import React, { lazy } from "react";

// 어드민
const Home = lazy(() => import("../pages/Home/Home"));

const AboutUs = lazy(() => import("../pages/AboutUs/AboutUs"));
const Goal = lazy(() => import("../pages/Goal/Goal"));
const OutPut = lazy(() => import("../pages/OutPut/OutPut"));
const ContactUs = lazy(() => import("../pages/ContactUs/ContactUs")); // 새로 추가

// 전체 404 Not Found 페이지
// const NotFound = lazy(() => import("../components/error/NotFound"));

const routes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <AboutUs /> },
  { path: "/goal", element: <Goal /> },
  { path: "/output", element: <OutPut /> },
  { path: "/contact", element: <ContactUs /> }, // 새로 추가
  // { path: "*", element: <NotFound /> },
];

export default routes;
